import React from 'react'
import axios from 'axios'
import {isMobile} from "react-device-detect"

import Lottie from 'react-lottie';
import screenAnimation from '../../lotties/screen.json';
import afficheAnimation from '../../lotties/affiche.json';

const DATA=[
    {
        "heading": "Kehila",
        "link":"/admin",
        "bg":"linear-gradient(50deg, rgba(40, 20, 131, 0.76) 15%, rgba(143, 110, 213, 0.89) 70%, rgba(215, 130, 217, 0.74) 94%)"
    },
    {
        "heading": "CRM",
        "link":"/crm",
        "bg":"linear-gradient(100deg, rgba(40, 20, 131, 0.76) 15%, rgba(143, 110, 213, 0.89) 70%, rgba(215, 130, 217, 0.74) 94%)"
    },
    {
        "heading": "Louah",
        "link":"/louah",
        "bg":"linear-gradient(150deg, rgba(40, 20, 131, 0.76) 15%, rgba(143, 110, 213, 0.89) 70%, rgba(215, 130, 217, 0.74) 94%)"
    },
    {
        "heading": "Classeur",
        "link":"/classeur",
        "bg":"linear-gradient(200deg, rgba(40, 20, 131, 0.76) 15%, rgba(143, 110, 213, 0.89) 70%, rgba(215, 130, 217, 0.74) 94%)"
    },
    {
        "heading": "Tseda-Smart",
        "link":"/tseda-smart",
        "bg":"linear-gradient(250deg, rgba(40, 20, 131, 0.76) 15%, rgba(143, 110, 213, 0.89) 70%, rgba(215, 130, 217, 0.74) 94%)"
    },
]

class Feature extends React.Component{

    render(){
        return (
            <section id="decouvrir" className="ptb-50" >

                <div className="col-12">
                    <div className="section-heading text-center">
                        <h1>Nos Solutions</h1>
                    </div>
                </div>
                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div style={{maxWidth:1500,display:"flex",flexDirection:isMobile?"column":"row",alignItems:"center",justifyContent:isMobile?"center":"space-around",flexWrap:"wrap",columnGap:20,rowGap:30}}>

                    {DATA.map((item,index)=>{
                        return(
                            <div className="card-accueil">
                                <a href={item.link}>
                                <div className={"card-accueil-image"} key={index} style={{background:item.bg}}>
                                    <div style={{height:200,width:"100%",backgroundColor:"rgba(0,0,0,0.5)",borderRadius:20,display:"flex",alignItems:"center",justifyContent:"center"}}>
                                        <h2 style={{color:"#FFF",fontFamily: 'Dancing Script',fontWeight:"bold",fontSize:50}}>{item.heading}</h2>
                                    </div>
                                </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
                </div>
            </section>
        )
    }
}

export default Feature
