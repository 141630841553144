import React, {Component} from 'react'


import Header2 from '../components/Header/header2'

const DATA="Article 1. FINALITE DES TRAITEMENTS\n" +
    "L'entreprise MIMTECH au numéro de SIREN 918 125 840 (ci-après dénommée « KEHILA »), dont le siège est établi au 34 rue Claude Nicolas Ledoux à Créteil (France), exploite l’application mobile du même nom. Cette application sert essentiellement à faciliter l’envoi régulier de dons à un panel d’Associations partenaires. Dans ce cadre, KEHILA est amené à collecter des informations relatives aux Utilisateurs, qui sont enregistrées dans ses bases de données. \n" +
    "\n" +
    "Ces enregistrements permettent : \n" +
    "\n" +
    "- d’émettre les reçus fiscaux \n" +
    "\n" +
    "- de faciliter la formulation de dons ou de promesses de dons futurs \n" +
    "\n" +
    "- de maintenir les Utilisateurs informés des évolutions à venir relatives à l’Application, notamment : \n" +
    "\n" +
    "• le référencement de nouvelles Associations Partenaires \n" +
    "\n" +
    "• l’ajout de nouvelles fonctionnalités \n" +
    "\n" +
    "• l’évolution éventuelles des CGU et/ou de la présente Politique de Confidentialité \n" +
    "\n" +
    "• des opérations de maintenance susceptibles de modifier l’accès à l’Application \n" +
    "\n" +
    "- de transmettre ses coordonnées aux associations que l’Utilisateur soutient s’il a donné son consentement.\n" +
    "\n" +
    "Article 2. BASE LÉGALE DES TRAITEMENTS\n" +
    "- Émettre les reçus fiscaux : la base légale du traitement est le respect d’une obligation légale incombant à l’Utilisateur (cf. article 6.1.b du Règlement Général sur la Protection des Données encadrant le traitement des données personnelles sur le territoire de l’Union européenne, ci-après nommé le « RGPD »). En l’occurrence, il s’agit de celle d’être en mesure de présenter ce reçu pour bénéficier d’une réduction d’impôts ; pour les résidents fiscaux français, en application des articles 200, 238 bis et 885-0 V bis A du Code Général des Impôts (CGI). \n" +
    "En vertu du principe de minimisation des données, si l’Utilisateur ne souhaite pas recevoir de reçus, il demeure libre de ne renseigner aucune information relative à son identité (cf. article 5.1.c du RGPD). \n" +
    "\n" +
    "- Faciliter la formulation de dons ou de promesses de don futurs : la base légale du traitement est l’intérêt légitime de KEHILA (cf. article 6.1.f du RGPD), à savoir offrir un service novateur, à même d’optimiser les dotations accordées à ses Associations partenaires. \n" +
    "\n" +
    "- Informer au sujet d’évolutions de l’Application : la base légale du traitement est l’intérêt légitime de KEHILA (cf. article 6.1.f du RGPD), à savoir promouvoir l’Application auprès de ses Utilisateurs. \n" +
    "\n" +
    "- Transmettre les coordonnées de l’Utilisateur aux Associations partenaires qu’il soutient : la base légale du traitement est le consentement (cf. article 6.1.a du RGPD), et comme exigé par l’article L. 34-5 du code des postes et des communications électroniques.\n" +
    "\n" +
    "Article 3. CATÉGORIES DE DONNÉES COLLECTÉES\n" +
    "- Identité : nom, prénom, adresses postale et électronique, pays de résidence fiscale \n" +
    "\n" +
    "- Moyen de paiement : pays d’émission, marque, type (débit / crédit), date de fin de validité, et 4 derniers chiffres du numéro de la carte bancaire. Le cryptogramme visuel n’est jamais enregistré ou conservé. \n" +
    "\n" +
    "- Historique des dons et promesses de dons : montant, devise, date, association bénéficiaire, mode de saisie. \n" +
    "\n" +
    "Article 4. DESTINATAIRES DES DONNÉES\n" +
    "- KEHILA est le destinataire de l’ensemble des catégories de données énoncées ci-dessus. \n" +
    "\n" +
    "- Sur la base de l’anonymat, les données relatives à l’activité des Utilisateurs peuvent être analysées par KEHILA, ses Associations partenaires ou des prestataires spécialisés dans ce type d’analyse de données. \n" +
    "\n" +
    "Article 5. DURÉE DE CONSERVATION\n" +
    "- Identité : pendant toute la durée où le compte de l’Utilisateur est actif. En cas de demande de suppression de son Compte, la suppression de ces données interviendra dans un délai maximum de 24 heures.\n" +
    "- Reçus fiscaux : au titre des obligations comptables, ces documents sont conservés pour une durée minimum de six (6) ans à compter de leur émission. \n" +
    "\n" +
    "- Données relatives aux dons et promesses de dons : ces données sont conservées pour une durée indéterminée par KEHILA. En cas de suppression de Compte, elles sont anonymisées. \n" +
    "\n" +
    "- Données relatives au moyen de paiement : ces données sont immédiatement supprimées en cas de clôture du Compte. \n" +
    "Il est à noter qu’en cas de changement du moyen de paiement de l’Utilisateur, les données relatives à la précédente carte bancaire sont conservées. Dans ce cas, l’intérêt légitime poursuivi est la prévention de la fraude. De même, il reste possible, même après suppression du Compte, d’accéder à l’adresse IP de l’appareil utilisé pour chaque paiement réalisé. \n"

export default class Confidentialite extends Component{
    render(){
        return(
            <div>
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header2 />


                        <section className="bg-secondary ptb-90">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="section-heading ">
                                            <h3>Politique de Confidentialité</h3>
                                        </div>
                                    </div>
                                </div>

                                <p dangerouslySetInnerHTML={{__html:DATA.replaceAll("\n","<br/>")}}></p>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        )
    }
}
