import React from 'react'

let bg = "/img/bg_video2.png"
let bgCss = "no-repeat center center / cover"
let bgStyle = {
    background: 'url('+bg+')'+bgCss
}

class VideoSection3 extends React.Component {
    render(){
        return (
            <div>
                {/* start app video section */}
                <div id="video-app" className="video-app-1"
                    style={bgStyle}>
                    <div className="overlay-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="video-play-button">

                                        <iframe width="420" height="315"
                                                src="https://www.youtube.com/embed/tgbNymZ7vqY?auto-play=1">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={this.props.imageData} alt="" className="img-responsive" />
                        {/* </div>end overlay */}
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoSection3;

