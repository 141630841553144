import React from "react";
import axios from 'axios'
import {isMobile} from "react-device-detect"

let bg = "/img/admin-screen0.png"
let bgCss = "no-repeat center center fixed"
let bgStyle = {
    background: 'url(' + bg + ')' + bgCss
}

const DATA = {
    "heading": "Fonctionnalités Principales de l'administration",
    "content": "Nous mettons à disposition pour toutes les associations une plateforme d'administration en ligne sécurisé.",
    "image": "/img/waves-shape.svg",
    "featureData":[
        {
            "id": 1,
            "featureName": "Communication",
            "featureIcon": "fa-solid fa-tower-broadcast",
            "featureHeading": "Horaires & Événements",
            "featureImage": "/img/admin/admin1.png",
            "content": "Kehila dispose d'une fonctionnalité de <b>Calcul des horaires</b> en fonctions des horaires Halah'ique (Shoulh'an Harouh, <b>Chabbad.org</b>) les horaires sont calculé chaque semaine en fonctions de la <b>géolocalisation de la synagogue</b>.<br/>Il est possible d'ajouter des évènements ainsi que des liens annexes (Visio Conference, inscription en ligne, Etc.)",
        },
        {
            "id": 2,
            "featureName": "Finances",
            "featureIcon": "fa-solid fa-coins",
            "featureHeading": "Promesses & Cagnottes",
            "featureImage": "/img/admin/admin2.png",
            "content": "Les Promesse de Dons, <b>Un poids</b> pour les fidèles et une <b>Gestion complexe</b> pour l'association. <b>Plus maintenant !</b><br/>le responsable enregistre directement les promesses de dons et les fidèles peuvent la régler à leur rythme.<br/>Afin de <b>Sensibilisé et d'engager</b> les fidèles au sein de la communauté Kehila permet la creation de Cagnottes (Mini Campagne) afin de <b>Soutenir l'association pour des objectifs spécifique</b> (Achat de Livres, Réparation Mobilier, Etc.) ",
        },
        {
            "id": 3,
            "featureName": "Services",
            "featureIcon": "fa-solid fa-gears",
            "featureHeading": "Services & Abonnements",
            "featureImage": "/img/admin/admin3.png",
            "content": "La majorité des synagogues proposent des services (Mikvé Femme/Homme,Place des Fetes,Etc.).<br/>Afin de facilité <b>l'organisation et la gestion du paiement</b> nous vous mettons à disposition des outils capable d'administrer tous vos <b>Services temporaires ou quotidiens</b>",
        },
        {
            "id": 4,
            "featureName": "Comptabilité",
            "featureIcon": "fa-solid fa-chart-line",
            "featureHeading": "Comptabilité Simplifié",
            "featureImage": "/img/admin/admin4.png",
            "content": "La Comptabilité ainsi que la gestion de la trésorerie peut etre <b>Un Fardeaux</b> pour les associations.<br/>Kehila vous mes a dispositions <b>Une Gestion simplifié des transactions</b> ainsi que des outils <b>Prévisionnelle</b> sur votre trésorerie",
        },
    ]
}

class Feature extends React.Component {

    state = {
        initData: {},
        featureData: []
    }
    componentDidMount() {
        this.setState({
            initData: DATA,
            featureData: DATA.featureData
        })
    }

    render() {
        return (
            <div>
                <section id="admin" className="overview-section ptb-90" style={bgStyle}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-heading text-center white-text overview-heading">
                                    <h3 className="white-text">{this.state.initData.heading}</h3>
                                    <p>{this.state.initData.content}</p>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="nav-center overview-center-tab">
                                    <ul className="nav nav-tabs">
                                        {this.state.featureData.map((item, idx) => {
                                            return (
                                                <li key={`fa_${idx}`} className={`${item.id === 1 ? 'active' : ''}`}><a href={`#nav-${idx + 1}`} data-toggle="tab">
                                                    <i style={{marginRight:"10px"}} className={item.featureIcon}></i> {item.featureName}
                                                </a></li>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <div className="tab-content-wrap">
                                    <div className="tab-content clearfix">
                                        {this.state.featureData.map((item, idx) => {
                                                return (
                                                    <div key={`fe_${idx}`} className={`tab-pane fade ${item.id === 1 ? 'active in' : ''}`} id={`nav-${idx + 1}`}>
                                                        <div className="col-12 col-md-7">
                                                            <div className="overview-feature-content-image">
                                                                <img src={item.featureImage} alt="" className="img-responsive" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-5">
                                                            <div className="overview-feature-content">
                                                                <h6>{item.featureHeading}</h6>
                                                                <p dangerouslySetInnerHTML={{__html:item.content}}></p>
                                                                    <ul className="overview-list hidden-xs hidden-sm">
                                                                        <li><span className="ti-timer"></span>Gagner du Temps</li>
                                                                        <li><span className="ti-bar-chart"></span>Gérer votre Trésorerie</li>
                                                                        <li><span className="ti-headphone-alt"></span>Support en ligne 24/6</li>
                                                                        <li><span className="ti-world"></span>Accessible 24/24H</li>
                                                                    </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-shape">
                        <img src={this.props.shapeImage} alt="" />
                    </div>
                </section>
            </div>
        )
    }
}
export default Feature
