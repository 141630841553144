import React, { Component } from 'react'
import axios from 'axios'
import {Link} from "react-router-dom";

//const BASE_URL = "https://my-json-server.typicode.com/lhmisho/React-Tutorial/themeOneFooterSection";

const DATA={
    "image": "/img/Kehila/12.png",
    "socialMedia":[
        {
            "id": 1,
            "iconClass": "fa fa-facebook",
            "link": "/"
        },
            {
                "id": 2,
                "iconClass": "fa fa-twitter",
                "link": "/"
            },
            {
                "id": 3,
                "iconClass": "fa fa-linkedin",
                "link": "/"
            },
            {
                "id": 4,
                "iconClass": "fa fa-google-plus",
                "link": "/"
            },
            {
                "id": 5,
                "iconClass": "fa fa-youtube",
                "link": "/"
            }
        ]
}

class Footer extends Component{

    state = {
        data: [],
        initData: {}
    }
    componentDidMount(){
        this.setState({
            data: DATA.socialMedia,
            initData: DATA
        })
    }

    render(){
        return(
            <footer className="footer-section bg-secondary ptb-60">
            <div className="footer-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <div className="footer-single-col text-center">
                                <img src={this.state.initData.image} alt="" style={{height:"60px"}} />
                                <div className="footer-social-list">
                                    <ul className="list-inline">
                                        <Link to="/confidentialite"><li>Politique de Confidentialité</li></Link>
                                        <Link to="/cgu"><li>Conditions Général d'utilisations</li></Link>


                                        {this.state.data.map((item, idx) =>{
                                            //return <li key={`fo_${idx}`}><a href={item.link}> <i className={item.iconClass}></i></a></li>
                                        })}
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>&copy; Copyright <a href="#">SAS MIMTECH</a> from Syn Assist
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        )
    }
}

export default Footer
