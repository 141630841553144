import React, { Component } from 'react'
import axios from 'axios'


let bg = "/img/card_bg.png"
let bgCss = "no-repeat center center fixed"
let bgStyle = {
    background: 'url('+bg+')'+bgCss,
    backgroundSize:"cover",
}

//const BASE_URL = "https://my-json-server.typicode.com/lhmisho/React-Tutorial/themeOneDownloadSection";

const DATA={
    "heading": "Télécharger l'application Maintenant !",
    "content": "Profitez au plus vite d'une application révolutionnaire !",
    "image": "/img/appstore.png",
    "data":[
        {
            "id": 1,
            "heaing": "Télécharger sur",
            "category": "App Store",
            "iconClass": "fa-brands fa-apple",
            "extraaClass": "",
            "link":"https://apps.apple.com/fr/app/kehila/id6444421357"
        },
        {
            "id": 2,
            "heaing": "Disponible sur",
            "category": "Google Play",
            "iconClass": "fa-brands fa-google-play",
            "extraaClass": "",
            "link":"https://play.google.com/store/apps/details?id=com.mimtech.kehila&hl=fr"
        }
    ]
}

class Download extends Component{

    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: DATA,
            data: DATA.data
        })
    }

    render(){
        return(
            <section className="download-section" style={bgStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-md-5 hidden-sm">
                        <div className="download-app-img">
                            <img src={this.state.initData.image} alt="app download" className="img-responsive" style={{borderRadius:"20px"}} />
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12">
                        <div className="download-app-text">
                            <h3>{this.state.initData.heading}</h3>
                            <p dangerouslySetInnerHTML={{__html:this.state.initData.content}} />
                            <div className="download-app-button row pl-15">
                                {this.state.data.map((item, idx) => {
                                    return(
                                        <a key={`dw_${idx}`} href={item.link} className={`download-btn ${item.extraaClass}`}>
                                            <span className={item.iconClass}></span>
                                            <p>
                                                <small>{item.heaing}</small>
                                                <br /> {item.category}
                                            </p>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}
export default Download
