import React, { Component } from "react";


class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.myForm = React.createRef()
  }

  initialState={
    nom: '',
    email: '',
    tel: '',
    asso: '',
    message: ''
  }

  state = this.initialState

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitHangler = async (event) => {
    event.preventDefault();
    console.log(this.state)

    let text=''

    text+="Nom: "+this.state.nom+"\n"
    text+="Association: "+this.state.asso+"\n"
    text+="\n"+this.state.message

    const url="https://wa.me/33767377415/?text="+encodeURI(text)

    window.open(url)
  }

  render() {
    return (
      <div>
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-us-form"
        >
          <h6>Contactez nous </h6>

          <div className="row">

            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    id="nom"
                    name="nom"
                    placeholder="Nom Complet *"
                    required
                    onChange={this.changHandler}
                    value={this.state.nom}
                />
              </div>
            </div>

            <div className="col-sm-6 col-xs-12">
              <div className="form-group">
                <input
                    type="text"
                    name="asso"
                    value={this.state.asso}
                    onChange={this.changHandler}
                    size="40"
                    className="form-control"
                    id="asso"
                    placeholder="Votre Association *"
                    required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="form-group">
                <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="7"
                    cols="25"
                    placeholder="Votre message"
                    onChange={this.changHandler}
                    value={this.state.message}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-20">
              <button
                type="submit"
                className="btn softo-solid-btn pull-right"
                id="btnContactUs"
              >
                Contactez nous via Whatsapp
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
