import React from 'react'

const Header = () => {
    return (
        <div>
            {/* start header section */}
            <header className="header">
                {/* start navbar */}
                <div className="navbar navbar-default top-nav-collapse">
                    <div className="container">
                        <div className="row">
                            <div className="navbar-header page-scroll">
                                <a className="navbar-brand page-scroll" href="/">
                                    <img src={"/img/Logos Site.png"} alt="logo" style={{height:"60px"}} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end navbar */}
            </header>
            {/* end header section */}
        </div>
    )
}


export default Header;
