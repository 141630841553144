import React from "react";
import Lottie from "react-lottie";
import LottieMigrate from "../../lotties/message_hero.json";
import {isMobile} from "react-device-detect"

let bg = "/img/hero-section-bg-2.jpg";
let bgCss = "no-repeat center center / cover";
let bgStyle = {};

let initData = {
  heading: "ApeTech Provide -",
  content:
    "Our software platform and custom development allow healthcare institutions to create comprehensive cross-platform solutions that ensure positive patient experiences.",
  heroImage: "/img/faq-right.png",
  actionShapeImage: "/img/shape-bg.png"
};

let slider_action_data = [
    {
        link: "#",
        btnClass: "btn softo-solid-btn",
        faClass: "fa-brands fa-whatsapp",
        value: "Whatsapp"
    },
    {
      link: "#",
      btnClass: "btn softo-solid-btn",
      faClass: "fa-regular fa-comment",
      value: "SMS"
    },
    {
        link: "#",
        btnClass: "btn softo-solid-btn",
        faClass: "fa-regular fa-envelope",
        value: "Email"
    },
  ];

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieMigrate,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

class HeroSection extends React.Component {

    state = {
        initData: initData,
        sliderData: slider_action_data
    }

    render() {
        return (
            <div>
                <section id="hero" className="section-lg section-hero hero-slider-section-one" style={bgStyle}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="hero-content-wrap">
                                    <div className="hero-content typed-strings">
                                        <h1>Le bon message au bon moment.</h1>
                                        <p>Laissez-nous faire, Concentrez-vous sur l'essentiel.</p>
                                        <div className="slider-action-btn">
                                            {this.state.sliderData.map((item, idx) => {
                                                return(
                                                    <a key={`sl_${idx}`} href={item.link} className={item.btnClass} style={{padding:isMobile?"5px 10px":"5px 30px"}}>
                                                        <i className={item.faClass}></i> {item.value}
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div style={{backgroundColor:"#FFF",borderRadius:50,marginTop:isMobile?-100:100,marginBottom:isMobile?100:0}}>
                                    <Lottie
                                        options={defaultOptions}
                                        height={350}
                                        width={350}
                                        isStopped={false}
                                        isPaused={false}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="section-shape">
                        <img src={this.state.initData.actionShapeImage} alt="" />
                    </div>
                </section>
            </div>
        );
  }
}

export default HeroSection;
