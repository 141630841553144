import React from 'react'

import Header from '../components/Header/header'
import Pricing from '../components/Pricing/pricing2'
import PromoSection from '../components/PromoSection/promosection2'
import Testimonial from '../components/Testimonial/testimonial'
import Faq from "../components/Faq/faq";
import Download from '../components/DownloadSection/downloadTwo'
import Blog from '../components/Blogs/singleBlog'
import Contact from '../components/Contact/contact'
import Footer from '../components/footer/footer'

import HeroSection from '../components/HeroSection/heroThree'
import Feature from '../components/Feature/featureThree'
import CustomerSection from "../components/customerSection/customerSection";
import Tarif1 from "../components/Tarifs/Tarif1";


class ThemeThree extends React.Component{
    componentDidMount() {
        document.title = 'Kehila - Back Office';
    }
    render(){
        return(
            <div>
                <div id="preloader">
                    <div id="loader"></div>
                </div>
                {/* End Preloader */}
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header />
                        <HeroSection shapeImage={"/img/shap.png"} />
                        <PromoSection />
                        <Feature shapeImage={"/img/waves-shape.svg"} />
                        <Pricing />
                        <Faq />
                        <CustomerSection/>
                        <Tarif1/>
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

export default ThemeThree
