import React, {Component} from 'react'


import Header from '../components/Header/header'
import HeroSection from '../components/HeroSection/heroFour.js'
import ProductCount from '../components/ProductCount/productCount'
import PromoSection from '../components/PromoSection/promosection3'
import Feature from '../components/Feature/feature4'
import VideoSection from '../components/VideoSection/videosection'
import Pricing from '../components/Pricing/pricing'
import Testimonial from '../components/Testimonial/testimonial'
import Faq from "../components/Faq/faq";
import Download from '../components/DownloadSection/download'

import Blog from '../components/Blogs/singleBlog'
import Contact from '../components/Contact/contact'
import Footer from '../components/footer/footer'
import CustomerSection from "../components/customerSection/customerSection";
import Tarif2 from "../components/Tarifs/Tarif2";
import VideoSection2 from "../components/VideoSection/videosection2";

class ThemeFour extends Component{
    componentDidMount() {
        document.title = 'Kehila - CRM';
    }
    render(){
        return(
            <div>
                <div id="preloader">
                    <div id="loader"></div>
                </div>
                {/* End Preloader */}
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header />
                        <HeroSection />
                        <ProductCount />
                        <PromoSection/>
                        <VideoSection2 imageData={"img/bg-wave-sym.png"} />
                        <Feature />
                        <CustomerSection/>
                        <Tarif2/>
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

export default ThemeFour
