import React, {Component} from 'react'


import Header from '../components/Header/header'
import HeroSection from '../components/HeroSection/hero9'
import PromoSection from '../components/PromoSection/promosection'
import Feature from '../components/Feature/feature7'
import VideoSection from '../components/VideoSection/videosection'
import Download from '../components/DownloadSection/download'

import Contact from '../components/Contact/contact'
import Footer from '../components/footer/footer'
import CustomerSection from "../components/customerSection/customerSection";
import Tarif1 from "../components/Tarifs/Tarif1";
import Alert from "../components/Feature/Alert";

export default class Theme8 extends Component{
    componentDidMount() {
        document.title = 'Kehila - Syn Assist';
    }

    render(){
        return(
            <div>
                <div id="preloader">
                    <div id="loader"></div>
                </div>
                {/* End Preloader */}
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header />
                        <HeroSection />
                        <Alert/>
                        <Feature />
                        <CustomerSection/>
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}
