import React from "react";
import axios from "axios";

const DATA={
  "promoData":[
        {
          "id": 1,
          "heading": "Conçu pour vous",
          "content": "Le CRM conçut de <b>A à Z</b> pour les responsables de <b>communauté juive</b>.",
          "iconClass": "ti-ruler-alt-2"
        },
          {
            "id": 2,
            "heading": "Communication Ciblé",
            "content": "Utilisez les <b>Catégories</b> et les <b>Familles</b> pour mieux cibler vos messages.",
            "iconClass": "ti-target"
          },
          {
            "id": 4,
            "heading": "Recherche Avancé",
            "content": "Concevez vos propres <b>Filtres personnalisés</b> afin d'adapter vos besoins.",
            "iconClass": "ti-search"
          },
          {
            "id": 3,
            "heading": "Carte interactive",
            "content": "Retrouvez tous vos contacts <b>autour de vous</b> et ciblez vos messages par <b>secteur</b>.",
            "iconClass": "ti-map-alt"
          },
      ]
}

class PromoSection extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.setState({
      data: DATA.promoData
    });
  }

  render() {
    return (
      <div>
        {/* start promo section */}
        <section className="promo-section ptb-90">
          <div className="promo-section-wrap">
            <div className="container">
              <div className="row">
                {this.state.data.map((item, idx) => {
                  return (
                    <div key={`s_${idx}`} className="col-md-3 col-sm-6" >
                      <div className="bg-secondary single-promo-section text-center" style={{height:"200px"}}>
                        <div className="single-promo-content">
                          <span className={item.iconClass}></span>
                          <h6>{item.heading}</h6>
                          <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                        </div>
                        <div className="line"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* end promo section */}
      </div>
    );
  }
}

export default PromoSection;
