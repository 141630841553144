import React from "react";
import axios from "axios";

const DATA={
  "promoData":[
        {
          "id": 1,
          "heading": "Facile à Utiliser",
          "content": "Kehila rend le paiements des <b>Dons</b> bien plus facile et bien plus <b>rapide</b>.",
          "iconClass": "ti-face-smile"
        },
          {
            "id": 2,
            "heading": "Promesses de dons",
            "content": "Régler vos promesses de dons en <b>plusieurs fois</b> et en un clique",
            "iconClass": "ti-receipt"
          },
          {
            "id": 4,
            "heading": "Fiscalité",
            "content": "Retrouver <b>rapidement</b> tous vos documents légaux au meme endroit.",
            "iconClass": "ti-files"
          },
          {
            "id": 3,
            "heading": "Tout & Partout",
            "content": "Retrouver directement les <b>informations importantes</b> quand vous en avez besoin.",
            "iconClass": "ti-world"
          },
      ]
}

class PromoSection extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.setState({
      data: DATA.promoData
    });
  }

  render() {
    return (
      <div>
        {/* start promo section */}
        <section className="promo-section ptb-90">
          <div className="promo-section-wrap">
            <div className="container">
              <div className="row">
                {this.state.data.map((item, idx) => {
                  return (
                    <div key={`s_${idx}`} className="col-md-3 col-sm-6" >
                      <div className="bg-secondary single-promo-section text-center" style={{height:"200px"}}>
                        <div className="single-promo-content">
                          <span className={item.iconClass}></span>
                          <h6>{item.heading}</h6>
                          <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                        </div>
                        <div className="line"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* end promo section */}
      </div>
    );
  }
}

export default PromoSection;
