import React from 'react'
import axios from 'axios'

const DATA={
    "heading": "Commissions",
    "content": "L'application Kehila ne stock aucune cartes bancaires. De ce fait nous utilisons la solution de <a href='https://squareup.com/fr/fr' target='_blank'>Square</a> afin d'effectuer des Paiements en ligne.",
    "pricingData":[
        {
            "id": 1,
            "heading": "Square",
            "priceType": "Individual",
            "minPrice": "1.4",
            "subPrice": "25",
            "packageTime": "Transaction"
        },
        {
            "id": 2,
            "heading": "Kehila",
            "priceType": "Business",
            "minPrice": "0",
            "subPrice": "0",
            "packageTime": "Transaction"
        },
    ]
}

class Pricing extends React.Component {

    state = {
        data: {},
        pricingData: []
    }

    componentDidMount(){
        this.setState({
            data: DATA,
            pricingData: DATA.pricingData
        })
    }

    render(){
        return(
            <div>
                {/* start pricing */}
                <section id="pricing" className="pricing-section ptb-90">
                <div className="pricing-section-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-heading text-center">
                                    <h3>{this.state.data.heading}</h3>
                                    <p dangerouslySetInnerHTML={{__html:this.state.data.content}}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="bg-color-sky-light">
                                <div className="content-md container">
                                    <div className="row" style={{display:"flex",justifyContent:"center",flexWrap:"wrap"}}>
                                        {this.state.pricingData.map((item, idx) => {
                                            return(
                                                <div key={`pricing_${idx}`} className="col-xs-12 col-md-4">
                                                {/* start pricing single */}
                                                    <div className="pricing-single bg-secondary">
                                                        <div className="pricing-single-header">
                                                            <h4>{item.heading}</h4>
                                                            <span>{item.type}</span>
                                                        </div>
                                                        <div className="pricing-single-content">
                                                            <div className="mb-40">
                                                                <span className="main-price">{item.minPrice}</span>
                                                                <span className="price-icon-sign"><i className="fa fa-percent"></i></span>
                                                                <span className="sub-price">+ {item.subPrice} cent.</span>
                                                                <span className="main-price-info">{item.packageTime}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                    {/* end row */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                {/* end pricing */}
            </div>
        )
    }
}

export default Pricing
