import React from 'react'
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";

import {isMobile} from "react-device-detect"

const images = [
    "asso1.png",
    "asso2.jpeg",
    "asso3.png",
    "asso4.png",
    "asso5.png",
    "asso6.jpeg",
    "asso7.jpg",
    "asso8.png",
    "asso9.jpeg",
    "asso10.png",
    "asso11.png",
    "asso12.png",
    "asso13.png",
    "asso14.png",
    "asso15.png",
]

class CustomerSection extends React.Component {

    shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    render() {

        var sliderMobileHeight=isMobile?80:120

        return (
            <div>
                {/* start customers section */}
                <div className="customers-section pt-40">
                    <div className="customers-wrap">
                        <div className="container pb-50">
                            <div className={"row"}>
                                <div className="col-12">
                                    <div className="section-heading text-center">
                                        <h2>Ils nous font Confiance !</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div style={{ height: sliderMobileHeight+20 }}>
                                        <Marquee velocity={10}>
                                            {this.shuffle(images).map((item,index) => (
                                                <img key={index} src={"/img/client/"+item} style={{height:sliderMobileHeight,marginLeft:50}} />
                                            ))}
                                        </Marquee>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end customers section */}
            </div>
        );
    }
}
export default CustomerSection;
