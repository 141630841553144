import React from "react";
import axios from "axios";

const DATA={
  "promoData":[
        {
          "id": 1,
          "heading": "Flexible",
          "content": "L'administration est adapté pour <b>mobile</b> afin d'etre <b>efficace</b> ou que vous soyez.",
          "iconClass": "ti-mobile"
        },
          {
            "id": 2,
            "heading": "Horaires Calculé",
            "content": "Les <b>horaires</b> de votre communauté automatiquement calculé.",
            "iconClass": "ti-time"
          },
          {
            "id": 3,
            "heading": "Soyez à jour",
            "content": "Publiez vos <b>évènements</b> ainsi que vos <b>annonces</b> facilement.",
            "iconClass": "ti-calendar"
          },
          {
            "id": 4,
            "heading": "Puissant",
            "content": "Gestion <b>automatique</b> et outils de <b>comptabilité</b> simplifié.",
            "iconClass": "ti-rocket"
          }
      ]
}

class PromoSection extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.setState({
      data: DATA.promoData
    });
  }

  render() {
    return (
      <div>
        {/* start promo section */}
        <section className="promo-section ptb-90">
          <div className="promo-section-wrap">
            <div className="container">
              <div className="row">
                {this.state.data.map((item, idx) => {
                  return (
                    <div key={`s_${idx}`} className="col-md-3 col-sm-6" >
                      <div className="bg-secondary single-promo-section text-center" style={{height:"200px"}}>
                        <div className="single-promo-content">
                          <span className={item.iconClass}></span>
                          <h6>{item.heading}</h6>
                          <p dangerouslySetInnerHTML={{__html: item.content}}></p>
                        </div>
                        <div className="line"></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* end promo section */}
      </div>
    );
  }
}

export default PromoSection;
