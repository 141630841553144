import React from 'react'
import axios from 'axios'

const DATA={
    "content": "Nous améliorons continuellement l'application Kehila afin de vous permettre de profitez de fonctionnalités utiles au quotidien au sein de votre communauté.",
    "heading": "Fonctionnalités de l'application",
    "image": "/img/features-screen.png",
    "featureDataOne":[
        {
            "id": 2,
            "iconClass": "fa fa-sitemap",
            "heading": "Tout en une seule appli.",
            "content": "Toutes vos synagogues et vos associations favorites au meme endroit."
        },
        {
            "id": 1,
            "iconClass": "fa fa-credit-card",
            "heading": "Paiement Rapide & Sécurisé",
            "content": "Enregistrement des Cartes Bancaires pour plus de simplicité (optionnel)."
        },

        {
            "id": 3,
            "iconClass": "fa fa-timeline",
            "heading": "Restez à jour",
            "content": "Retrouvez les événements de vos associations et restez informé continuellement."
        }
    ],
    "featureDataTwo":[
        {
            "id": 1,
            "iconClass": "fa fa-file-pdf",
            "heading": "Reçu Cerfa Instantané",
            "content": "Apres chaque don un reçu fiscal sera généré et sauvegardé sur votre compte en ligne"
        },
        {
            "id": 2,
            "iconClass": "fa fa-hand",
            "heading": "Promesses inoubliable",
            "content": "Réglez vos Promesses de dons en 1 clic et en plusieurs fois."
        },
        {
            "id": 3,
            "iconClass": "fa fa-shield-halved",
            "heading": "Sécurité Renforcé",
            "content": "Authentification Biométrique et Sécurité des donnees sur le Cloud."
        }
    ]
}

class Feature extends React.Component{

    state = {
        data : {},
        featureDataOne: [],
        featureDataTwo: []
    }

    componentDidMount(){
        this.setState({
            data: DATA,
            featureDataOne: DATA.featureDataOne,
            featureDataTwo: DATA.featureDataTwo
        })
    }

    render(){
        return (
            <div>
                {/* start features section */}
                <section id="features" className="bg-secondary ptb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-heading text-center">
                                    <h3>{this.state.data.heading}</h3>
                                    <p>{this.state.data.content}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-4 col-sm-6">
                                {/* feature single start */}
                                {this.state.featureDataOne.map((item, idx) => {
                                    return(
                                        <div key={`f_${idx}`} className="single-feature mb-5">
                                            <div className="feature-icon">
                                                <div className="icon icon-shape bg-color white-text">
                                                    <i className={item.iconClass}></i>
                                                </div>
                                            </div>
                                            <div className="feature-content">
                                                <h5>{item.heading}</h5>
                                                <p className="mb-0">{item.content}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* feature single end */}
                            </div>
                            <div className="col-md-4 hidden-sm hidden-xs">
                                <div className="feature-image">
                                    <div className="phone">
                                        <img src={this.state.data.image} alt="" className="img-responsive" style={{borderRadius:"20px"}} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                {/* feature single start */}
                                {this.state.featureDataTwo.map((item, idx) => {
                                    return(
                                        <div key={`ft_${idx}`} className="single-feature mb-5">
                                            <div className="feature-icon">
                                                <div className="icon icon-shape bg-color white-text">
                                                    <i className={item.iconClass}></i>
                                                </div>
                                            </div>
                                            <div className="feature-content">
                                                <h5>{item.heading}</h5>
                                                <p className="mb-0">{item.content}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* feature single end */}
                            </div>
                        </div>

                    </div>
                </section>
                {/* end features section */}
            </div>
        )
    }
}

export default Feature
