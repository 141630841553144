import React from 'react'
import Switch from "react-switch";
import {isMobile} from "react-device-detect"

const tarifs=[
    {
        best:false,
        name:"Basic",
        label:"Pour les tres petites communautés",
        description:"Adapté aux <b>petites communautés</b> pour des paiements exceptionnels.",
        primaryColor:"#fdc9b3",
        backgroundColor:"#fef1eb",
        prixMois:0,
        prixAnnuel:0,
        mentionAnnuel:false,
        commissionBank:"0.84% + 14ct / Paiement",
        commission:"1.2% / Paiement",
        features:[
            {label:"Page de Paiement en ligne"},
            {label:"Référencement sur l'application"},
            {label:"Gestion des Horaires"},
            {space:true},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},
        ]
    },
    {
        best:false,
        name:"Essentiel",
        label:"Pour les communautés actives",
        description:"Adapté aux <b>communautés actives</b> pour des <b>fidèles actifs</b> avec des paiements récurrents.",
        primaryColor:"#ffbd52",
        backgroundColor:"#fff3e0",
        prixMois:24,
        prixAnnuel:0,
        mentionAnnuel:false,
        commissionBank:"0.84% + 14ct / Paiement",
        commission:"0% / Paiement",
        features:[
            {
                add:true,
                label:"Toutes les fonctionnalités de Basic"
            },
            {space:true},
            {label:"Paiement Illimité / sans Commissions"},
            {label:"Plateforme d'administration Mobile"},
            {label:"Gestion des Horaires"},
            {label:"Gestion des événements"},
            {label:"Actualités"},
            {space:true},
            {label:"Stockage Cloud"},
            {label:"Migration vers Kehila"},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},

        ]
    }
]


const packs=[
    {
        best:false,
        name:"Pack Finance",
        label:"Pour les responsables rigoureux",
        description:"Adapté aux <b>responsables de communautés</b> & <b>Trésoriers</b> afin de gérer finement vos comptes et votre <b>comptabilité</b>.",
        primaryColor:"#9DE4F1",
        backgroundColor:"#E0FBFF",
        prixMois:59,
        prixAnnuel:54,
        mentionAnnuel:true,
        commissionBank:"0.84% + 14ct / Paiement",
        commission:"0% / Paiement",
        features:[
            {
                add:true,
                label:"Toutes les fonctionnalités de Essentiel"
            },
            {space:true},
            {label:"Prélèvements Bancaire"},
            {label:"Cagnottes"},
            {label:"Campagnes de don (max 50k)"},
            {label:"Services & Abonnements"},
            {label:"Gestion des Promesses de Dons"},
            {space:true},
            {label:"Comptabilité Simplifié"},
            {label:"Gestion Facture & Reçu"},
            {label:"Rapprochement Bancaire"},
            {label:"Gestion Legal"},
            {label:"Gestion du Budget"},
            {label:"Statistiques Prévisionnelles"},
            {space:true},
            {label:"Stockage Cloud"},
            {label:"Migration vers Kehila"},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},

        ],

        options:[
            {label:"Utilisateur Supplémentaire (+19€)"},
            {label:"Gestion Multi Associations (+19€)"},
        ]
    },
    {
        best:true,
        name:"Pack Communication",
        label:"Pour les communautés dynamiques",
        description:"Adapté aux <b>communautés dynamiques en mouvement</b> avec un vrai besoin de <b>Communication</b>.",
        primaryColor:"#9df1d9",
        backgroundColor:"#e9fcf7",
        prixMois:59,
        prixAnnuel:54,
        mentionAnnuel:true,
        commissionBank:"0.84% + 14ct / Paiement",
        commission:"0% / Paiement",
        features:[
            {
                add:true,
                label:"Toutes les fonctionnalités de Essentiel"
            },
            {
                add:true,
                label:"Toutes les fonctionnalités du CRM"
            },
            {space:true},
            {label:"Liaison intelligente des paiements"},
            {label:"Statistiques et Rapports"},
            {space:true},
            {label:"Stockage Cloud"},
            {label:"Migration vers Kehila"},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},
        ],

        options:[
            {label:"Utilisateur Supplémentaire (+19€)"}
        ]
    },
    {
        best:false,
        name:"Pack Full",
        label:"Pour les grandes communautés",
        description:"Retrouvez <b>Toutes les Fonctionnalités</b> du Système <b>Kehila</b> ainsi que du <b>CRM</b>.",
        primaryColor:"#F19DCB",
        backgroundColor:"#FFE0FB",
        prixMois:99,
        prixAnnuel:91,
        mentionAnnuel:true,
        commissionBank:"0.84% + 14ct / Paiement",
        commission:"0% / Paiement",
        features:[
            {
                add:true,
                label:"Toutes les fonctionnalités de Essentiel"
            },
            {
                add:true,
                label:"Toutes les fonctionnalités du CRM"
            },
            {
                add:true,
                label:"Toutes les fonctionnalités du Pack Finance"
            },
            {
                add:true,
                label:"Toutes les fonctionnalités du Pack Com."
            },
            {space:true},
            {label:"Campagnes de don (illimité)"},
            {space:true},
            {label:"Stockage Cloud"},
            {label:"Migration vers Kehila"},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},
        ],

        options:[
            {label:"Utilisateur Supplémentaire (+19€)"},
            {label:"Gestion Multi Associations (+19€)"},
        ]
    },

]

export default class Tarif1 extends React.Component {

    state={
        annuel:false
    }


    renderTarif(item,pack=false)
    {

        var marginCard=(item.best || !pack)?20:70
        if(isMobile){
            marginCard=20
        }

        return(
            <div style={{backgroundColor:item.backgroundColor,borderRadius:20,maxWidth:400,marginTop:marginCard,boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}}>
                {item.best  && (
                    <div style={{height:50,borderTopLeftRadius:20,borderTopRightRadius:20,backgroundColor:item.primaryColor,display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <h4 style={{fontWeight:"bold",fontFamily: 'Dancing Script'}}>Populaire</h4>
                    </div>
                )}
                <div style={{padding:20}}>
                    <div className={"col-12"}>
                        <div className={"col-12"}>
                            <h3 style={{fontWeight:"bold",fontFamily: 'Dancing Script'}}>{item.name}</h3>
                        </div>
                        <div className={"col-12"}>
                            <span style={{padding:"5px 20px",borderRadius:5,backgroundColor:item.primaryColor,borderColor:item.primaryColor,color:"#000",fontFamily: 'Alkatra'}}>{item.label}</span>
                        </div>
                        <div style={{marginTop:10}}>
                            <p dangerouslySetInnerHTML={{__html:item.description}} />
                        </div>
                    </div>

                    <div className={"col-12"} style={{marginTop:20}}>
                        <div style={{display:"flex",alignItems:"center",padding:10}}>
                            <span style={{fontWeight:"bold",fontSize:40,color:"#000",fontFamily: 'Dancing Script'}}>{(this.state.annuel && pack)?item.prixAnnuel:item.prixMois}€</span>
                            <span style={{marginLeft:10,color:"#000"}}>ttc <b>par mois</b></span>
                        </div>

                        {(this.state.annuel && pack) &&(
                            <div style={{display:"flex",alignItems:"center",padding:10,marginTop:10}}>
                                <span style={{marginRight:10,textDecorationLine:"line-through",fontWeight:"bold",fontSize:15,color:"#A2A2A2",fontFamily: 'Alkatra'}}>{item.prixMois*12}€</span>
                                <i className="fa-solid fa-arrow-right" style={{marginRight:10,color:"#A2A2A2"}}></i>
                                <span style={{fontWeight:"bold",fontSize:16,color:"#000",fontFamily: 'Alkatra'}}>{item.prixMois*11}€</span>
                                <span style={{marginLeft:10,color:"#000",fontSize:12}}>ttc <b>par an</b></span>
                            </div>
                        )}

                    </div>

                    <div className={"col-12"} style={{marginTop:20,display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <a href={"#contact"} style={{backgroundColor:"#281483",color:"#FFF",borderRadius:50,padding:"20px 30px",fontSize:18,fontWeight:"600"}}>Souscrire Maintenant</a>
                    </div>

                </div>

                <div className={"col-12"} style={{padding:"10px 20px",backgroundColor:item.primaryColor}}>
                    <ul>
                        <li>
                            <p style={{color:"#000"}}>Commissions Bancaire: <b>{item.commissionBank}</b></p>
                        </li>
                        <li>
                            <p style={{color:"#000"}}>Commissions Kehila: <b>{item.commission}</b></p>
                        </li>
                    </ul>
                </div>

                <div className={"col-12"} style={{padding:20,backgroundColor:"#FFF",borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
                    <div className={"col-12 text-center"}>
                        <span style={{fontFamily: 'Alkatra',color:"#000",fontSize:18}}>Principales fonctionnalitées {pack?"du":"de"} {item.name} :</span>
                    </div>

                    <div className={"col-12"} style={{padding:"20px 20px"}}>
                        <ul>
                            {
                                item.features.map((f,index2)=>{

                                    if(f.space){
                                        return <li key={index2} style={{marginTop:50}}/>
                                    }

                                    if(f.add)
                                    {
                                        return(
                                            <li key={index2} className={"row"} style={{marginTop:15}}>
                                                <span style={{padding:"5px 7px",borderRadius:100,backgroundColor:"grey",color:"#FFF"}}><i className="fa-solid fa-plus"></i></span>
                                                <span style={{color:"#000",fontWeight:"600",marginLeft:10}}>{f.label}</span>
                                            </li>
                                        )
                                    }

                                    return(
                                        <li key={index2} className={"row"} style={{marginTop:15}}>
                                            <span style={{padding:"5px 6px",borderRadius:100,backgroundColor:item.primaryColor,color:"#000"}}><i className="fa-solid fa-check"></i></span>
                                            <span style={{color:"#000",fontWeight:"600",marginLeft:10}}>{f.label}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    {item.options && (
                        <div>
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative",borderTop:"3px dashed "+item.primaryColor,marginTop:30}}>
                                <div className={"col-12"} style={{position:"relative",top:-18}}>
                                    <span style={{padding:"5px 20px",borderRadius:5,backgroundColor:item.backgroundColor,borderColor:item.primaryColor,color:"#000",fontFamily: 'Alkatra',fontSize:12}}>Options Supplémentaires</span>
                                </div>
                            </div>

                            <div className={"col-12"} style={{padding:"20px 20px",paddingTop:0}}>
                                <ul>
                                    {
                                        item.options.map((f,index2)=>{
                                            return(
                                                <li key={index2} className={"row"} style={{marginTop:15}}>
                                                    <span style={{padding:"4px 8px",borderRadius:100,backgroundColor:"grey",color:"#FFF"}}><i className="fa-solid fa-euro-sign"></i></span>
                                                    <span style={{color:"#000",fontWeight:"600",marginLeft:10}}>{f.label}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        )
    }


    render() {

        var containerStyle={display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"flex-start"}
        if(isMobile){
            containerStyle={padding:30,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}
        }

        return (
            <div>
                {/* start customers section */}
                <div className="pt-40" id={"tarifs"} style={{backgroundColor:"#f9f9f9",paddingBottom:50}}>
                        <div className={"row"}>
                            <div className="col-12">
                                <div className="section-heading text-center">
                                    <h1>Nos Tarifs</h1>
                                </div>
                            </div>

                            <div style={containerStyle}>
                                {tarifs.map((item,index)=>this.renderTarif(item))}
                            </div>

                            <div className="col-12 mt-70">
                                <div className="section-heading text-center">
                                    <h1>Nos Packs</h1>
                                </div>
                            </div>

                            {/*
                            <div className={"col-12"} style={{display:"flex",alignItems:"center",justifyContent:isMobile?"center":"flex-start"}}>
                                <div style={{marginLeft:isMobile?0:50,display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"#281483",width:370,borderRadius:10,padding:"5px 0"}}>
                                    <span style={{marginRight:10,color:this.state.annuel?"#C8C8C8":"#FFF",fontWeight:"600"}}>Mensuel</span>
                                    <Switch checkedIcon={false} uncheckedIcon={false} onColor={"#5de8c1"} offColor={"#D3D3D3"} onChange={(v)=>this.setState({annuel:v})} checked={this.state.annuel} />
                                    <span style={{marginLeft:10,color:!this.state.annuel?"#C8C8C8":"#FFF",fontWeight:"600"}}>Annuel</span>
                                    <span style={{marginLeft:10,color:"#5de8c1",fontWeight:"600"}}>= 1 mois Offert !</span>
                                </div>
                            </div>
                            */}
                            

                            <div style={containerStyle}>
                                {packs.map((item,index)=>this.renderTarif(item,true))}
                            </div>
                        </div>
                    </div>
                {/* end customers section */}
            </div>
        );
    }
}
