import React from 'react'
import axios from 'axios'
import {isMobile} from "react-device-detect"

import Lottie from 'react-lottie';
import screenAnimation from '../../lotties/screen.json';
import afficheAnimation from '../../lotties/affiche.json';

const DATA=[
    {
        "heading": "Personnalisable",
        "content": "Votre classeur personnalisé aux <b>couleurs de votre choix</b> avec votre logo",
        "right":<img src={"/img/classeur/classeur2.png"} style={{height:300}} />
    },
    {
        "heading": "Noussa'h Habbad & Séfarade",
        "content": "<b>Toutes les ventes de l'année imprimées</b> dans un seul classeur directement prêt à l'usage afin de vous faciliter l'organisation de vos ventes.<br/><br/>" +
            "Pour cela deux models vous sont proposé la version des <b>ventes Habbad</b> & la version des <b>ventes Sefarade</b>.",
        "right":(
            <img src={"/img/classeur/pageinit.png"} style={{height:300,border:"0px solid red"}} />
        )
    },
]

class Feature extends React.Component{

    render(){
        return (
            <div id="features">
                {DATA.map((item,index)=>{
                    var pair= index%2==0

                    var text=(
                        <div className={"col-12 col-md-6 "} style={{textAlign:isMobile?"center":"start"}}>
                            <h3 >{item.heading}</h3>
                            <p dangerouslySetInnerHTML={{__html:item.content}}/>
                        </div>
                    )

                    var image=(
                        <div className={"col-12 col-md-6"} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:isMobile?50:0}}>
                            {item.right}
                        </div>
                    )

                    return(
                        <section key={index} className={"ptb-90"}>
                            <div className="container">
                                <div className="row" >
                                    {isMobile && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && pair) && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && !pair) && (
                                        <div>{image} {text}</div>
                                    )}
                                </div>
                            </div>
                        </section>
                    )
                })}
            </div>
        )
    }
}

export default Feature
