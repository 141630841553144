import React from 'react'
import axios from 'axios'
import {isMobile} from "react-device-detect"

import Lottie from 'react-lottie';
import LottieMedia from '../../lotties/crm-medias.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieMedia,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const DATA=[
    {
        "heading": "Connecté à Google contact",
        "content": "Importez tous vos contacts en seul clique ainsi que les libellés et gardez vos habitudes.",
        "right":(
            <img src={"/img/crm/google.png"} style={{height:100}} />
        )
    },
    {
        "heading": "Rappels Hazkara & Anniversaire",
        "content": "Nous enverrons un SMS de rappel 8 jours avant la date afin de permettre a vos contacts de s'organiser et surtout de ne pas oublier !",
        "right":(
            <img src={"/img/crm/rappel.png"} style={{height:300}} />
        )
    },
    {
        "heading": "Document, image et messages personnalisés",
        "content": "Envoyez des documents PDF ainsi que des images ou des liens à tous vos contacts en quelques cliques (documents indisponibles via SMS)",
        "right":(
            <Lottie
                options={defaultOptions}
                height={200}
                width={200}
                isStopped={false}
                isPaused={false}/>
        )
    },
    {
        "heading": "Envoyez depuis votre compte Whatsapp",
        "content": "Notre système se connecte à votre compte Whatsapp et envoie les messages pour vous. de ce fait vous pourrez reprendre la discussion par la suite ! ",
        "right":(
            <img src={"/img/crm/whatsapp.png"} style={{height:100}} />
        )
    }
]

class Feature extends React.Component{

    render(){
        return (
            <div id="features">
                {DATA.map((item,index)=>{
                    var pair= index%2===0

                    var text=(
                        <div className={"col-12 col-md-6 "} style={{textAlign:isMobile?"center":"start"}}>
                            <h3 >{item.heading}</h3>
                            <p>{item.content}</p>
                        </div>
                    )

                    var image=(
                        <div className={"col-12 col-md-6"} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:isMobile?50:0}}>
                            {item.right}
                        </div>
                    )

                    return(
                        <section className={pair?"bg-secondary ptb-90":"ptb-90"}>
                            <div className="container">
                                <div className="row" >
                                    {isMobile && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && pair) && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && !pair) && (
                                        <div>{image} {text}</div>
                                    )}
                                </div>
                            </div>
                        </section>
                    )
                })}
            </div>
        )
    }
}

export default Feature
