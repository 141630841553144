import React from 'react'
import axios from 'axios'
import {isMobile} from "react-device-detect"

import Lottie from 'react-lottie';
import screenAnimation from '../../lotties/screen.json';
import afficheAnimation from '../../lotties/affiche.json';

const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: screenAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: afficheAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const DATA=[
    {
        "heading": "Quoi Quand et Ou",
        "content": "Choisissez comment ordonner vos pages et combien de temps il seront affichées.",
        "right":(
            <Lottie
                options={defaultOptions1}
                height={150}
                width={200}
                isStopped={false}
                isPaused={false}/>
        )
    },
    {
        "heading": "Administration Web en ligne",
        "content": "Administrer votre écran depuis chez vous et modifier directement vos pages.",
        "right":(
            <img src={"/img/louah/terre.png"} style={{height:100}} />
        )
    },
    {
        "heading": "Hayom Yom, Sefer Hamitsvot Etc.",
        "content": "Le Louah dispose de plein de contenu automatique afin de sensibilisé votre communauté à l'étude de la torah quotidienne.",
        "right":(
            <img src={"/img/louah/rambam.jpg"} style={{height:200,borderRadius:25}} />
        )
    },
    {
        "heading": "Affiches & Textes Personnalisées",
        "content": "Afficher vos évènements et vos messages a l'ensemble de votre communauté et communiquer facilement vos informations.",
        "right":(
            <Lottie
                options={defaultOptions2}
                height={200}
                width={200}
                isStopped={false}
                isPaused={false}/>
        )
    },
]

class Feature extends React.Component{

    render(){
        return (
            <div id="features">
                {DATA.map((item,index)=>{
                    var pair= index%2===0

                    var text=(
                        <div className={"col-12 col-md-6 "} style={{textAlign:isMobile?"center":"start"}}>
                            <h3 >{item.heading}</h3>
                            <p>{item.content}</p>
                        </div>
                    )

                    var image=(
                        <div className={"col-12 col-md-6"} style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:isMobile?50:0}}>
                            {item.right}
                        </div>
                    )

                    return(
                        <section className={pair?"bg-secondary ptb-90":"ptb-90"}>
                            <div className="container">
                                <div className="row" >
                                    {isMobile && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && pair) && (
                                        <div>{text} {image}</div>
                                    )}

                                    {(!isMobile && !pair) && (
                                        <div>{image} {text}</div>
                                    )}
                                </div>
                            </div>
                        </section>
                    )
                })}
            </div>
        )
    }
}

export default Feature
