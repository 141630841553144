import React from 'react'
import axios from 'axios'
import {isMobile} from "react-device-detect"

import Lottie from 'react-lottie';
import screenAnimation from '../../lotties/screen.json';
import afficheAnimation from '../../lotties/affiche.json';



class Alert extends React.Component{

    render(){
        return (
            <section id="alert" className="bg-info mt-50" >
                <div className="bg-info">
                    <div className="col-12">
                        <div className="section-heading text-center" style={{padding:"30px 20px"}}>
                            <h4>Synassist change de plateforme de paiement !</h4>
                            <p><b>Ce changement nous permet de vous proposer de nouveaux taux exceptionnels !</b></p>
                            <p>Nous passons donc de : <b>1.4% + 25ctms</b> / transaction à <b>0,84% + 14ctms</b> / transactions.</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Alert
