import React, {Component} from 'react'


import Header2 from '../components/Header/header2'
import {Link} from "react-router-dom";


export default class Cgu extends Component{
    render(){
        return(
            <div>
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header2 />


                        <section className="bg-secondary ptb-90">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="section-heading ">
                                            <h3>Conditions Général d'utilisations</h3>
                                        </div>
                                    </div>
                                </div>

                                <ul>
                                    <a href="/docs/kehila-cgu-v1.pdf" target="_blank"><li>CGU Applicable depuis le 20 Janvier 2023</li></a>
                                </ul>


                            </div>
                        </section>

                    </div>
                </div>
            </div>
        )
    }
}
