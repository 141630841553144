import React from 'react'
import axios from 'axios'
import Lottie from 'react-lottie';
import LottieMigrate from '../../lotties/migrate.json';


const DATA={
    "heading": "Un Système de Paiement",
    "content": "",
    "pricingData":[
        {
            "id": 1,
            "heading": "Square",
            "priceType": "Individual",
            "minPrice": "1.4",
            "subPrice": "25",
            "packageTime": "Transaction"
        },
        {
            "id": 2,
            "heading": "Kehila",
            "priceType": "Business",
            "minPrice": "0",
            "subPrice": "0",
            "packageTime": "Transaction"
        },
    ]
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieMigrate,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

class Pricing extends React.Component {

    state = {
        data: {},
        pricingData: []
    }

    componentDidMount(){
        this.setState({
            data: DATA,
            pricingData: DATA.pricingData
        })
    }

    render(){
        return(
            <div>
                {/* start pricing */}
                <section id="pricing" className="pricing-section ptb-90">
                <div className="pricing-section-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="section-heading text-center">
                                    <h3>{this.state.data.heading}</h3>
                                    <p>Vous etes décidé a nous rejoindre mais tout vos données sont ailleurs ?<br/><b>Aucun Problème !</b> nos équipes s'occupe de tout afin de migrer vos données vers notre système</p>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <Lottie
                                    options={defaultOptions}
                                    height={300}
                                    width={300}
                                    isStopped={false}
                                    isPaused={false}/>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                {/* end pricing */}
            </div>
        )
    }
}

export default Pricing
