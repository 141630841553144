import React from 'react'
import Switch from "react-switch";
import {isMobile} from "react-device-detect"

const tarifs=[
    {
        name:"CRM",
        description:"un CRM <b>Puissant</b>, <b>Mobile</b> et adapté a tous vos <b>Besoins</b>.",
        primaryColor:"#FDB3B3",
        backgroundColor:"#FEEBEB",
        prixMois:49,
        features:[
            {label:"Stockage Cloud"},
            {label:"Migration des Contacts"},
            {label:"Support Technique 24/7"},
            {label:"Assistance Téléphonique 24/7"},
            {space:true},
            {label:"Plateforme d'administration Mobile"},
            {label:"Gestion des Contacts"},
            {label:"Synchronisation Google"},
            {label:"Gestion des Catégories"},
            {label:"Gestion des Familles"},
            {label:"Gestion des Rendez Vous"},
            {label:"Agenda Intelligent"},
            {label:"Localisation Géographique"},
            {label:"Filtre Personnalisé"},
            {label:"Recherche Avancé"},
            {space:true},
            {label:"Campagne Whatsapp Illimité"},
            {label:"Campagne SMS"},
            {label:"Campagne Email Illimité"},
            {label:"Éditeur d'email Moderne"},
            {label:"Messages Personnalisés"},
            {label:"Rappel Hazkara Illimité (SMS)"},
            {label:"Rappel Anniversaires Illimité (SMS)"},
        ]
    }
]

export default class Tarif2 extends React.Component {

    state={
        annuel:false
    }


    renderTarif(item,pack=false)
    {

        var marginCard=(item.best || !pack)?20:70
        if(isMobile){
            marginCard=20
        }

        return(
            <div style={{backgroundColor:item.backgroundColor,borderRadius:20,maxWidth:400,marginTop:marginCard,boxShadow:"rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}}>
                <div style={{padding:20}}>
                    <div className={"col-12"}>
                        <div className={"col-12"}>
                            <h3 style={{fontWeight:"bold",fontFamily: 'Dancing Script'}}>{item.name}</h3>
                        </div>
                        <div style={{marginTop:10}}>
                            <p dangerouslySetInnerHTML={{__html:item.description}} />
                        </div>
                    </div>

                    <div className={"col-12"} style={{marginTop:20}}>
                        <div style={{display:"flex",alignItems:"center",padding:10}}>
                            <span style={{fontWeight:"bold",fontSize:40,color:"#000",fontFamily: 'Dancing Script'}}>{(this.state.annuel && pack)?item.prixAnnuel:item.prixMois}€</span>
                            <span style={{marginLeft:10,color:"#000"}}>ttc <b>par mois</b></span>
                        </div>
                    </div>


                </div>

                <div className={"col-12"} style={{padding:20,backgroundColor:"#FFF",borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
                    <div className={"col-12 text-center"}>
                        <span style={{fontFamily: 'Alkatra',color:"#000",fontSize:18}}>Fonctionnalitées du {item.name} :</span>
                    </div>

                    <div className={"col-12"} style={{padding:"20px 20px"}}>
                        <ul>
                            {
                                item.features.map((f,index2)=>{

                                    if(f.space){
                                        return <li key={index2} style={{marginTop:50}}/>
                                    }

                                    if(f.add)
                                    {
                                        return(
                                            <li key={index2} className={"row"} style={{marginTop:15}}>
                                                <span style={{padding:"5px 7px",borderRadius:100,backgroundColor:"grey",color:"#FFF"}}><i className="fa-solid fa-plus"></i></span>
                                                <span style={{color:"#000",fontWeight:"600",marginLeft:10}}>{f.label}</span>
                                            </li>
                                        )
                                    }

                                    return(
                                        <li key={index2} className={"row"} style={{marginTop:15}}>
                                            <span style={{padding:"5px 6px",borderRadius:100,backgroundColor:item.primaryColor,color:"#000"}}><i className="fa-solid fa-check"></i></span>
                                            <span style={{color:"#000",fontWeight:"600",marginLeft:10}}>{f.label}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

            </div>
        )
    }


    render() {

        var containerStyle={display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"flex-start"}
        if(isMobile){
            containerStyle={padding:30,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}
        }

        return (
            <div>
                {/* start customers section */}
                <div className="pt-40" id={"tarifs"} style={{backgroundColor:"#f9f9f9",paddingBottom:50}}>
                        <div className={"row"}>
                            <div className="col-12">
                                <div className="section-heading text-center">
                                    <h1>Nos Tarifs</h1>
                                </div>
                            </div>

                            <div style={containerStyle}>
                                {tarifs.map((item,index)=>this.renderTarif(item))}
                            </div>
                        </div>
                    </div>
                {/* end customers section */}
            </div>
        );
    }
}
