import React from "react";

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSix';


import Blog from '../components/Blogs/singleBlog'
import Contact from '../components/Contact/contact'
import Footer from '../components/footer/footer'
import CustomerSection from "../components/customerSection/customerSection";
import Videosection3 from "../components/VideoSection/videosection3";
import VideoSection2 from "../components/VideoSection/videosection2";
import VideoSection3 from "../components/VideoSection/videosection3";

class ThemeSix extends React.Component {
    componentDidMount() {
        document.title = 'Kehila - Tsedasmart';
    }
    render() {
        return (
            <div>
                <div id="preloader">
                    <div id="loader"></div>
                </div>
                {/* End Preloader */}
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header />
                        <HeroSection />
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

export default ThemeSix
