import React from "react";

let initData ={
  heading: "Le Classeur Kehila",
  content: "Un Classeur de promesses de dons adapté à vos besoins",
  image:    "/img/waves-shape.svg",
  sliderImage: "/img/iphone-x-frame.png",
}
let slider_action_data = [
  {
    link: "#contact",
    btnClass: "btn softo-solid-btn",
    faClass: "fa-brands fa-apple",
    value: "Commander"
  },
];

class HeroSection extends React.Component {
  // noinspection JSAnnotator
  state = {
    initData: {},
    sliderData: [],
    phoneImage: []
  };
  componentDidMount() {

    // you can do this by make a api call
    // axios
    //   .get(`${URL}`)
    //   .then(res => {
    //     this.setState({
    //       initData ....
    //     });
    //     // console.log(this.state.sliderData);
    //   })
    //   .catch(err => console.log(err));

    this.setState({
      initData: initData,
      sliderData: slider_action_data,
    })
  }

  render() {
    return (
      <div>
        {/* start hero section */}
        <section id="hero" className="section-lg section-hero section-circle">
          {/* background circle shape */}
          <div className="shape shape-style-1 shape-primary">
            <span className="circle-150"></span>
            <span className="circle-50"></span>
            <span className="circle-50"></span>
            <span className="circle-75"></span>
            <span className="circle-100"></span>
            <span className="circle-75"></span>
            <span className="circle-50"></span>
            <span className="circle-100"></span>
            <span className="circle-50"></span>
            <span className="circle-100"></span>
          </div>
          {/* background circle shape */}
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="hero-content-wrap">
                  <div className="hero-content">
                    <h1>{this.state.initData.heading}</h1>
                    <p>{this.state.initData.content}</p>
                    <div className="slider-action-btn">
                      {this.state.sliderData.map((data, idx) => {
                        return (
                          <a key={`sk_${idx}`} href={data.link} className={data.btnClass}>
                            {data.value}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="section-shape">
            <img src={this.state.initData.image} alt="" />
          </div>
        </section>
        {/* end hero section */}
      </div>
    );
  }
}

export default HeroSection;
