import React from 'react'
import {isMobile} from "react-device-detect"

let initData = {
  heading: "Un écran pour toutes vos informations.",
  content:"Nous vous proposons un écran entièrement réglable et facile d'utilisation afin d'afficher dans vos locaux les informations ainsi que vos affiches, messages et plus encore !",
  heroImage: "/img/faq-right.png",
  actionShapeImage: "/img/shape-bg.png"
};

let slider_action_data = [
  {
    link: "apple",
    btnClass: "btn softo-solid-btn",
    faClass: "fa fa-apple",
    value: "Appstore"
  },
  {
    link: "android",
    btnClass: "btn btn-icon",
    faClass: "fa fa-android",
    value: ""
  },
  {
    link: "windows",
    btnClass: "btn btn-icon",
    faClass: "fa fa-windows",
    value: ""
  }
];


class HeroSection extends React.Component{

    state = {
        initData: {},
        sliderData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            sliderData: slider_action_data
        })
    }

    render(){
        return(
            <div>
            {/* start slider background video section */}
        <div id="hero-section" className="section-lg section-hero section-circle">
            {/* background circle shape */}
            <div className="shape shape-style-1 shape-primary">
                <span className="circle-150"></span>
                <span className="circle-50"></span>
                <span className="circle-50"></span>
                <span className="circle-75"></span>
                <span className="circle-100"></span>
                <span className="circle-75"></span>
                <span className="circle-50"></span>
                <span className="circle-100"></span>
                <span className="circle-50"></span>
                <span className="circle-100"></span>
            </div>
            {/* background circle shape */}
            <div className="hero-background-video">
                <div className="video-section-wrap-two">
                    <div className="pattern-overlay">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-md-offset-2">
                                    <div className="hero-content text-center">
                                        <h1>{this.state.initData.heading}</h1>
                                        <p>{this.state.initData.content}</p>
                                    </div>
                                </div>

                                {!isMobile &&(
                                    <div style={{marginTop:50}}>
                                        <iframe src={"https://app.nechama.syn-assist.fr?org=1"} style={{aspectRatio:"16/9",height:600}}></iframe>
                                    </div>
                                )}

                                {isMobile &&(
                                    <div style={{marginTop:50}}>
                                        <iframe src={"https://app.nechama.syn-assist.fr?org=1"} style={{aspectRatio:"16/9",width:"90%"}}></iframe>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end slider background video section */}
            </div>
        );
    }
}
export default HeroSection;
