import React, { Component } from 'react'
import ContactForm from './contactfrom'
import axios from 'axios'

//const BASE_URL = "https://my-json-server.typicode.com/lhmisho/React-Tutorial/themeOneContactSection"

const DATA={
    "heading": "Vous êtes Convaincu ? <br/> êtes Convaincu ?",
    "content": "Utilisez le formulaire de contact et nous vous rappellerons dans les plus bref délais ou contactez nous directement.",
    "contact": "+33 7 67 37 74 15",
    "email": "contact.synassist@gmail.com"
}

class Contact extends Component {

    state = {
        data: {}
    }

    componentDidMount(){
        this.setState({
            data: DATA
        })
    }

    render() {
        return (
            <section id="contact" className="contact-us ptb-90">
                <div className="contact-us-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="section-heading">
                                    <h1>Contact</h1>
                                    <p>{this.state.data.content}</p>
                                </div>
                                <div className="footer-address">
                                    <ul>
                                        <li><i className="fa fa-phone"></i> <span>Téléphone: <a href={"tel:"+this.state.data.contact}>{this.state.data.contact}</a></span></li>
                                        <li><i className="fa fa-envelope"></i> <span>Email : <a href={"mailto:"+this.state.data.email}>{this.state.data.email}</a></span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Contact
