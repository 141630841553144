import React, { Component } from "react"
import axios from 'axios'

//const BASE_URL = "https://my-json-server.typicode.com/lhmisho/jsonserver/themeOneFaqSection";

const DATA={
    "heading": "FAQ",
    "content": "Évidemment il reste encore des questions en suspends, pour plus d'explications Contactez nous !",
    "image": "/img/Kehila/15.png",
    "data":[
        {
            "id": 1,
            "question": "Est il possible de réaliser des paiements via un site web ?",
            "answer": "Oui Bien sur, afin de répondre a tout les besoins nous mettons en place une 'passerelle de paiement' en ligne afin de rediriger les liens de paiements vers un site internet si le contact ne dispose pas de l'application Kehila."
        },
        {
            "id": 2,
            "question": "Est il possible d'envoyer un cerfa manuellement ? ",
            "answer": "Oui il est possible de remplir un cerfa manuellement via la plateforme d'administration, de ce fait le cerfa sera envoyer directement sur le mail du donateur."
        },
        {
            "id": 3,
            "question": "Comment partager un lien a mes contacts ?",
            "answer": "Afin de facilité votre communication nous mettons a disposition pour chaque Cagnotte ou Service un lien/qrcode afin de le partager a votre entourage et simplifier le processus de don."
        },
        {
            "id": 4,
            "question": "Ou sont stocké mes fichiers ?",
            "answer": "Tous vos documents et images sont stockés sur des serveurs sécurisé et sauvegardé afin de garantir leur accessibilités a tout moment."
        }
    ]
}

class Faq extends Component{

    state = {
        initData: {},
        data: []
    }

    componentDidMount(){
        this.setState({
            initData: DATA,
            data: DATA.data
        })
    }

    render(){
        return(
            <section id="faqs" className="faq-section ptb-90">
            <div className="faq-section-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-heading">
                                <h3>{this.state.initData.heading}</h3>
                                <p>{this.state.initData.content}</p>
                            </div>
                            <div className="panel-group" id="accordion">
                                {/* Start Single Item */}
                                {this.state.data.map((item, idx) => {
                                  return(
                                    <div key={`faq_${idx}`} className="panel panel-default">
                                        <div className="panel-heading" id={`heading_${idx}`}>
                                            <h4 className="panel-title">
                                                <a data-toggle="collapse" data-parent="#accordion" href={`#collapse_${idx}`} className="collapsed">
                                                    {item.question}
                                                </a>
                                            </h4>
                                        </div>
                                        <div id={`collapse_${idx}`} className="panel-collapse collapse">
                                            <div className="panel-body">
                                                <p>{item.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                  )
                                })}

                                {/* End Single Item */}
                            </div>
                        </div>
                        <div className="col-md-6 hidden-xs hidden-sm">
                            <div className="faq-img">
                                <img src={this.state.initData.image} className="img-responsive" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default Faq;
