import React from 'react'

import Header from '../components/Header/header'
import Contact from '../components/Contact/contact'
import Footer from '../components/footer/footer'

import HeroSection from '../components/HeroSection/hero8'
import Feature from '../components/Feature/feature6'
import Tarif4 from "../components/Tarifs/Tarif4";
import CustomerSection from "../components/customerSection/customerSection";


class ThemeSeven extends React.Component{
    componentDidMount() {
        document.title = 'Syn Assist - Classeur';
    }
    render(){
        return(
            <div>
            <div id="preloader">
                    <div id="loader"></div>
                </div>
                {/* End Preloader */}
                <div id="main" className="main-content-wraper">
                    {/* start inner content */}
                    <div className="main-content-inner">
                        <Header />
                        <HeroSection shapeImage={"/img/bg-wave-sym.png"} />
                        <Feature/>
                        <CustomerSection/>
                        <Tarif4/>
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

export default ThemeSeven
