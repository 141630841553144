import React from 'react'
import {isMobile} from "react-device-detect"

let bg = "/img/hero-section-bg-3.jpg";
let bgCss = "no-repeat center center / cover";
let bgStyle = {
    background: "url(" + bg + ")" + bgCss
};

const initData = {
    heading: "La première borne de don <b>Phygital<b/> à moindre coût.",
    image: "/img/tab-image-2.png"
}


class HeroSection extends React.Component {

    state = {
        sliderData: [],
        actionData: []
    }
    componentDidMount() {
        this.setState({
            sliderData: initData,
            actionData: initData.action
        })
        // console.log(this.state)
    }
    render() {
        return (
            <div>
                <section id="hero-section" className="hero-slider-section hero-slider-section-one" >
                    <div className="container">
                        <div className="item slider-bg-content">
                                <div className="col-12">
                                    <div className="hero-content" style={{display:"flex",flexDirection:"column",alignItems:"center",textAlign:"center"}}>
                                        <img src={"/img/Tsedasmart/4.png"} style={{height:300,marginTop:-200}}/>
                                        <h1 dangerouslySetInnerHTML={{__html:initData.heading}}/>
                                        <br/>
                                        <br/>
                                        <a target="_blank" href={"/img/Tsedasmart/plaquette-tsedasmart.pdf"} className={"btn softo-solid-btn"} style={{color:"#FFF"}}>
                                            Télecharger la Plaquette
                                        </a>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="section-shape">
                        <img src="/img/waves-shape.svg" alt="" />
                    </div>
                </section>
            </div>
        );
    }
}
export default HeroSection;
